import './App.css';
import AppHeader from './components/AppHeader/AppHeader';
import AppContent from './components/AppContent/AppContent';
import AppFooter from 'components/AppFooter/AppFooter';

function App() {
  return (
    <div>
      <AppHeader/>
      <AppContent/>
      <AppFooter/>
    </div>
  );
}
export default App;