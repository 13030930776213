import { useEffect, useState } from 'react';
import './ApiQuery.css';
function ApiQuery() {
    const [data, setData] = useState(null);  // Przechowujemy całe dane zwrócone przez API
    const [loading, setLoading] = useState(true);  // Stan ładowania
    const [error, setError] = useState(null);  // Stan błędów
    //const [adress] = useState('46.41.142.58:7777'); //Adres IP serwera
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://api.open.mp/servers/46.41.142.58:7777`);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();  // Pobieramy dane w formacie JSON
          setData(result);  // Ustawiamy dane w stanie
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (loading) {
      return <div>Pobieranie informacji...</div>;
    }
  
    if (error) {
      return <div>Wystąpił błąd: {error}</div>;
    }
  
    // Sprawdzamy, czy serwer jest aktywny
    if (!data.active) {
      return <div>Serwer aktualnie jest offline.</div>;
    }
    //console.log(Object(data));

    const formatDate = (dateString) => {
      const options = {
        year: "numeric", 
        month: "long", 
        day: "numeric", 
        weekday: 'long', 
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return new Date(dateString).toLocaleDateString(undefined, options)
    }
    return (
      <>
        <div className='server-details-head'>
          <h2>{data.ip}</h2>
        </div>
        <ul className='server-details-content'>
          <li>{data.core.hn}</li>
          <li>Mapa: {data.core.gm}</li>
          <li>Graczy: {data.core.pc}/{data.core.pm}</li>
          <li>Wersja: {data.core.vn}</li>
          <li>Język: {data.core.la}</li>
          <li>Wspierane wersje: {data.ru.allowed_clients}</li>
          <li>Worldtime: {data.ru.worldtime}</li>
          <li>Ostatni update: {formatDate(data.lastUpdated)}</li>
        </ul>
      </>
    );
}
export default ApiQuery;