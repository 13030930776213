import './AppContent.css';
import ApiQuery from '../ApiQuery/ApiQuery';
const AppContent = () => {
    const lata = 5;
    return(
        <>
            <div className="app-row">
                <div className="content-col" id="header">
                    <h2 className='h2-top'><span className='logo-letter-big'>G</span>aming <span className='logo-letter-big'>S</span>ide</h2>
                    <hr className='hr-top'/>
                    <div className='content-grid'>
                        <span className='text-center'>&#x1F3AE; IP: gaming-side.pl:7777</span>
                        <span className='text-center'>&#128185; Online</span>
                    </div>
                </div>
            </div>
            <div className="app-row" id='content'>
                <div className="content-col bg-default">
                    <h2 className='text-center'>Kilka słów o Gaming Side</h2>
                    <p>Nasz serwer <a href='https://www.open.mp' title='Open Multiplayer'>Open.mp</a> jest dostępny 24/7 i kompatybilny z dotychczasowymi klientami <a href='https://www.sa-mp.mp/' title='San Andreas Multiplayer'>SAMP</a> w wersjach 0.3.7 oraz 0.3DL na platformie stworzonej jako następcę SAMPa - modyfikację Open-MP. <strong>Nasz serwer jest w pełni stabilny i bezpieczny.</strong> Posiadamy wiele ciekawych systemów, które nie są dostępne na innych serwerach. Dołącz do naszej społeczności i odkryj najlepsze chwile rozrywki!</p>
                    <h2 className='text-center'>Czym tak naprawdę jest Gaming Side?</h2>
                    <ul>
                        <li>Hobbistyczny projekt serwera SAMP od graczy dla graczy</li>
                        <li>Gamemode rozwijany przez {lata} lat w miarę możliwości i czasu</li>
                        <li>Zawiera dynamiczny panel ze statystykami graczy oraz serwera</li>
                    </ul>
                </div>
                <div className="content-col bg-default-invert">
                    <ApiQuery/>
                </div>
            </div>
        </>
    )
}
export default AppContent;