import { useState } from 'react';
import './AppHeader.css';
const AppHeader = () =>{
    const [mobileOpened, setmobileOpened] = useState(false);
    const toggleSlide = () => {
        setmobileOpened(!mobileOpened);
    };
    function slideToElement(){
        console.log('slide test');
        document.getElementById("content").scrollIntoView();
    }
    return(
        <div className='app-header' id="top">
            <div className='header-logo'>
                <span className='logo-letter-big'>G</span>aming-<span className='logo-letter-big'>S</span>ide.pl
            </div>
            <div className='header-navbar'>
                <ul className='navbar-list'>
                    <li className='desktop-hidden' onClick={toggleSlide}>&#9776;</li>
                    <li className={`mobile-hidden ${mobileOpened ? 'open' : 'closed'}`} onClick={toggleSlide}>
                        <a href="/">Home</a>
                    </li>
                    <li className={`mobile-hidden ${mobileOpened ? 'open' : 'closed'}`} onClick={toggleSlide}>
                        <span onClick={slideToElement}>O serwerze</span>
                    </li>
                    <li className={`mobile-hidden ${mobileOpened ? 'open' : 'closed'}`} onClick={toggleSlide}>
                        <a href="/panel">Panel</a>
                    </li>
                    <li className={`mobile-hidden ${mobileOpened ? 'open' : 'closed'}`} onClick={toggleSlide}>
                        <a href="/blog">Blog</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default AppHeader;